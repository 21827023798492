<template>
  <layout selectedNav="data-source">
    <div class="page-content">
      <div class="left-side">
        <panel
          v-for="(category, index) in categories"
          :key="index"
          :data="category"
          :multiple="category.multiple"
          :active="currentType"
          @item-click="handleNavItemClick"
        >
        </panel>
      </div>
      <div class="right-content">
        <div class="filter-nav">
          <el-input
            v-model="form.keyword"
            clearable
            placeholder="请输入标题"
            @keyup.native.enter="handleSearch"
          >
            <img
              @click="handleSearch"
              slot="prefix"
              src="@/assets/images/search-icon1.png"
            />
          </el-input>
          <el-button type="primary" @click="toggleShowAdvancedSearch"
            >高级筛选</el-button
          >
          <el-button
            type="primary"
            class="download-btn"
            @click="handleDownload"
            :loading="showDownloadLoading"
          >
            <!-- <span>{{ form.total }}个</span> -->
            <img src="@/assets/images/download-w.png" />
            <span>下载全部目录</span>
          </el-button>
        </div>
        <div
          :class="['advanced-filter-nav', { 'is-expaned': showAdvanceSearch }]"
        >
          <el-select
            v-model="form.advanced.type"
            clearable
            placeholder="请选择资源类型"
          >
            <el-option
              v-for="(category, index) in resourceTypeList"
              :key="index"
              :label="category.label"
              :value="category.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.advanced.area"
            clearable
            placeholder="请选择数据领域"
          >
            <el-option
              v-for="(category, index) in dataAreaList"
              :key="index"
              :value="category.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.advanced.scene"
            clearable
            placeholder="请选择应用场景"
          >
            <el-option
              v-for="(category, index) in applicationSceneList"
              :key="index"
              :label="category.label"
              :value="category.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="form.advanced.keyword"
            clearable
            placeholder="添加关键字"
          ></el-input>
          <el-button type="text" @click="handleAdvancedSearch">
            <img src="@/assets/images/search-icon2.png" />
          </el-button>
        </div>
        <div class="main-content" v-loading="showLoading">
          <div class="sort-nav" v-if="showList">
            <div
              class="sort-item"
              v-for="(sortItem, index) in sortList"
              :key="index"
              @click="handleSortClick(sortItem)"
            >
              <span>{{ sortItem.label }}</span>
              <img
                :src="
                  require(`@/assets/images/${currentSortIconPath(
                    sortItem.sortField
                  )}.png`)
                "
              />
            </div>
          </div>
          <div class="resource-list" v-if="showList">
            <router-link
              class="resource-item"
              v-for="(resource, index) in resourceList"
              :key="index"
              :to="{ name: 'DataSourceDetail', params: { id: resource.id } }"
            >
              <div class="resource-item__header">
                <div class="resource-item__title">
                  {{ resource.title }}
                  <span class="terms"> ({{ resource.bloom }})</span>
                </div>
                <div class="resource-item__header-right">
                  <span class="resource-item__view">
                    <img src="@/assets/images/viewed.png" />
                    <span>{{ resource.view }}浏览量</span>
                  </span>
                  <span class="resource-item__download">
                    <img src="@/assets/images/download.png" />
                    <span>{{ resource.download }}下载量</span>
                  </span>
                  <el-tag
                    class="resource-item__category"
                    effect="dark"
                    :color="resource.category_btn_color"
                    type=""
                    >{{ resource.category }}</el-tag
                  >
                </div>
              </div>
              <div class="resource-item__content">
                <div class="resource-item__description">
                  <span class="resource-item__description-title">摘要</span>
                  {{ resource.description }}
                </div>
              </div>
              <div class="resource-item__footer">
                <span :title="resource.from" style="width:252px">
                  数据来源：
                  <span>
                    <span style="color:#56A9FF">市</span>/{{
                      resource.szf
                    }}</span
                  >
                  <span style="margin-left: 10px;"
                    ><span style="color:#56A9FF">区</span> /{{
                      resource.qzf
                    }}</span
                  >
                </span>
                <!-- <span :title="resource.scene">应用场景：{{ resource.scene }}</span> -->
                <span :title="resource.updateTime" style="margin-left:306px"
                  >更新日期：{{ resource.updateTime }}</span
                >
                <span :title="resource.publishTime"
                  >发布日期：{{ resource.publishTime }}</span
                >
              </div>
            </router-link>
          </div>
          <el-pagination
            v-if="showList"
            background
            layout="prev, pager, next"
            :total="form.total"
            :current-page.sync="form.currentPage"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handlePageChange"
          >
          </el-pagination>
          <empty v-if="showEmpty"></empty>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "@/views/Layouts";
import Panel from "@/views/Layouts/panel";
import dayjs from "dayjs";
import XLSX from "xlsx";
import Empty from "@/views/Layouts/empty";

// const CATEGORY_COLOR_MAP = {
//   卫生健康: "#0B5483",
//   教育科技: "#DFA01D",
//   经济建设: "#B35895",
//   公共安全: "#56A9FF",
//   民生服务: "#7D9246",
//   信用服务: "#D78010",
//   资源环境: "#45B5BD",
//   城市建设: "#4577BD",
//   机构团体: "#AF4F0A",
//   文化休闲: "#8264CA",
//   道路交通: "#BD4545",
//   社会发展: "#484692"
// };

export default {
  components: {
    Layout,
    Panel,
    Empty
  },
  data() {
    return {
      categories: [],
      navList: [],
      currentType: "",
      currentModule: null,
      currentSortField: "UPDATEDATE",
      currentSortValue: -1,
      sortList: [
        {
          label: "下载次数",
          value: "downloadCount",
          sortField: "XZCS"
        },
        {
          label: "浏览次数",
          value: "viewCount",
          sortField: "FWCS"
        },
        {
          label: "更新时间",
          value: "updateTime",
          sortField: "UPDATEDATE"
        },

        {
          label: "发布时间",
          value: "dataName",
          sortField: "CREATEDATE",
          sortValue: 0
        }
      ],
      resourceList: [],
      form: {
        keyword: "",
        advanced: {
          szf: "",
          qzf: "",
          type: "",
          area: "",
          scene: "",
          keyword: ""
        },
        total: 0,
        currentPage: 1
      },
      titles: {
        title: "标题",
        remark: "摘要",
        scene: "应用场景",
        datarealm: "数据领域",
        kfsx: "开放属性",
        zylx: "资源类型",
        gxpl: "更新频率",
        createtime: "发布日期",
        updatetime: "更新日期",
        access: "访问次数",
        downsize: "下载次数",
        ipone: "联系方式",
        qzf: "区政府",
        szf: "市政府"
      },
      showList: false,
      showEmpty: false,
      showLoading: false,
      showAdvanceSearch: false,
      showDownloadLoading: false
    };
  },
  computed: {
    resourceTypeList() {
      return this.categories.length
        ? this.categories
            .filter(item => item.type === "type")[0]
            .list.map(item => {
              return {
                label: item.label,
                value: item.label
              };
            })
        : [];
    },
    dataAreaList() {
      return this.categories.length
        ? this.categories
            .filter(item => item.type === "data")[0]
            .list.map(item => {
              return {
                label: item.label,
                value: item.label
              };
            })
        : [];
    },
    applicationSceneList() {
      return this.categories.length
        ? this.categories
            .filter(item => item.type === "app")[0]
            .list.map(item => {
              return {
                label: item.label,
                value: item.label
              };
            })
        : [];
    }
  },
  methods: {
    currentSortIconPath(sortField) {
      let sortIconPathMap = {
        1: "sort-up",
        0: "sort",
        "-1": "sort-down"
      };
      return this.currentSortField === sortField
        ? sortIconPathMap[this.currentSortValue]
        : sortIconPathMap[0];
    },
    handleSortClick(item) {
      let defaultSortValueMap = [1, 0, -1];
      if (this.currentSortField === item.sortField) {
        this.currentSortValue =
          defaultSortValueMap[
            (defaultSortValueMap.indexOf(this.currentSortValue) + 1) %
              defaultSortValueMap.length
          ];
      } else {
        this.currentSortValue = 1;
        this.currentSortField = item.sortField;
      }
      this.getList();
    },
    handleSearch() {
      this.form.advanced = {
        szf: "",
        qzf: "",
        type: "",
        area: "",
        scene: "",
        keyword: ""
      };
      this.form.currentPage = 1;
      this.getList();
    },
    handleAdvancedSearch() {
      this.currentType = "";
      this.currentModule = null;
      this.form.currentPage = 1;
      this.getList();
    },
    toggleShowAdvancedSearch() {
      this.showAdvanceSearch = !this.showAdvanceSearch;
    },
    getNavList() {
      this.$api.dataSource
        .menu()
        .then(data => {
          if (data.code === 200) {
            this.categories = [
              {
                title: "数据领域",
                type: "data",
                list: data.data.data.value.map(item => {
                  return {
                    label: item.datatitle,
                    value: item.size
                  };
                })
              },
              {
                title: "资源类型",
                type: "type",
                list: data.data.type.value.map(item => {
                  return {
                    label: item.datatitle,
                    value: item.size
                  };
                })
              },
              {
                title: "应用场景",
                type: "app",
                list: data.data.app.value.map(item => {
                  return {
                    label: item.datatitle,
                    value: item.size
                  };
                })
              },
              {
                title: "数据来源",
                multiple: true,
                list: [
                  {
                    title: "市政府",
                    type: "szf",
                    list: data.data.szf.value.map(item => {
                      return {
                        label: item.datatitle,
                        value: item.size
                      };
                    })
                  },
                  {
                    title: "区政府",
                    type: "qzf",
                    list: data.data.qzf.value.map(item => {
                      return {
                        label: item.datatitle,
                        value: item.size
                      };
                    })
                  }
                ]
              }
            ];
          } else {
            this.$message({
              type: "error",
              message: "获取数据资源类型失败"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getList() {
      this.showLoading = true;
      this.showList = false;
      this.showEmpty = false;
      this.$api.dataSource
        .list({
          likename: this.form.advanced.keyword || this.form.keyword,
          szf: this.form.advanced.szf,
          qzf: this.form.advanced.qzf,
          type: this.form.advanced.type,
          data: this.form.advanced.area,
          app: this.form.advanced.scene,
          pagenum: this.form.currentPage,
          ...(this.currentType
            ? { [`${this.currentModule}`]: this.currentType }
            : {}),
          ...(this.currentSortValue
            ? {
                sx: this.currentSortValue > 0 ? "asc" : "desc",
                paix: this.currentSortField
              }
            : {})
        })
        .then(data => {
          console.log(data);
          this.showLoading = false;
          if (data.code === 200 && data.data.result.length) {
            this.showList = true;
            this.resourceList = data.data.result.map(item => {
              return {
                id: item.ID,
                title: item.title,
                description: item.remark,
                scene: item.scene,
                updateTime: item.updatetime
                  ? dayjs(new Date(item.updatetime)).format("YYYY-MM-DD")
                  : "",
                publishTime: item.createtime
                  ? dayjs(new Date(item.createtime)).format("YYYY-MM-DD")
                  : "",
                category: item.datarealm,
                view: item.access,
                download: item.downsize,
                from: item.type,
                szf: item.szf,
                qzf: item.qzf,
                bloom: item.bloom,
                category_btn_color: item.color
              };
            });
            this.form.total = data.data.totalRowNum;
          } else {
            this.showEmpty = true;
          }
        })
        .catch(err => {
          console.log(err);
          this.showLoading = false;
          this.showEmpty = true;
        });
    },
    handleNavItemClick({ item, index, data }) {
      console.log(item, index, data);
      this.currentType = item.label;
      this.currentModule = data.type;
      this.form.advanced = {
        szf: "",
        qzf: "",
        type: "",
        area: "",
        scene: "",
        keyword: ""
      };
      this.getList();
    },
    handlePageChange() {
      this.getList();
    },
    handleDownload() {
      this.showDownloadLoading = true;
      this.$api.dataSource
        .export()
        .then(data => {
          this.showDownloadLoading = false;
          if (data.code === 200) {
            console.log(data.data);
            // let titles = {};
            // // console.log(data.data.title);
            // data.data.title.forEach(item => {
            //   let itemArr = item.split(":");
            //   titles[itemArr[1]] = itemArr[0];
            // });
            // console.log(titles)
            let results = [];
            data.data.object.map((row, index) => {
              let rowData = {
                序号: index + 1,
                标题: row.title,
                摘要: row.remark,
                应用场景: row.scene,
                数据领域: row.datarealm,
                开放属性: row.kfsx,
                资源类型: row.zylx,
                更新频率: row.gxpl,
                发布日期: row.createtime,
                更新日期: row.updatetime,
                访问次数: row.access,
                下载次数: row.downsize,
                联系方式: row.ipone,
                区政府部门: row.qzf,
                市政府部门: row.szf
              };
              // console.log(rowData)
              results.push(rowData);
            });
            // console.log(results);
            let ws = XLSX.utils.json_to_sheet(
              results,
              Object.values(this.titles)
            );
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet");
            XLSX.writeFile(wb, "数据资源目录.xlsx");
          } else {
            this.$message({
              type: "error",
              message: data.message || "数据资源目录下载失败"
            });
          }
        })
        .catch(() => {
          this.showDownloadLoading = false;
          this.$message({
            type: "error",
            message: "网络出现未知错误"
          });
        });
    }
  },
  mounted() {
    this.getNavList();
    let query = this.$route.query;
    if (query.multiple) {
      this.form.advanced = {
        ...this.form.advanced,
        keyword: query.keyword || "",
        type: query.type || "",
        area: query.area || "",
        scene: query.scene || ""
      };
    } else {
      this.form.keyword = query.keyword || "";
      (this.currentType = query.data || query.app),
        (this.currentModule =
          ("data" in query && "data") || ("app" in query && "app"));
    }
    this.getList();
  },
  updated() {
    window.scroll(0, 0);
  }
};
</script>
<style lang="scss" scoped>
.page-content {
  width: 1200px;
  display: flex;
  margin: 40px auto;

  .left-side {
    width: 230px;

    .panel-wrapper {
      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .right-content {
    flex: 1;
    margin-left: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .filter-nav {
      display: flex;
      align-items: center;

      .el-input {
        flex: 1;

        ::v-deep .el-input__icon {
          font-size: 20px;
          line-height: 50px;
        }

        img {
          cursor: pointer;
        }
      }

      .el-button {
        min-width: 120px;
        height: 50px;
        margin-left: 20px;
        background: #5883f8;
      }

      .download-btn {
        display: inline-flex;
        align-items: center;

        img {
          margin-left: 18px;
          margin-right: 9px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .advanced-filter-nav {
      margin-top: 20px;
      border-top: 1px solid #5883f8;
      max-height: 0px;
      overflow: hidden;
      display: flex;
      align-items: center;

      &.is-expaned {
        padding: 20px;
        background: #ffffff;
        max-height: fit-content;
      }

      .el-select {
        width: 200px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
          line-height: 40px;
          height: 40px;
        }
      }

      .el-input {
        width: 200px;
        ::v-deep .el-input__inner {
          line-height: 40px;
          height: 40px;
        }
      }

      .el-button {
        height: 50px;
        margin-left: 20px;
        background: #5883f8;
        width: 70px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .main-content {
      width: 100%;
      min-height: 200px;

      ::v-deep .el-loading-mask {
        background: #f6f6f6;
      }
    }

    .sort-nav {
      display: flex;
      align-items: center;
      background: #ffffff;
      height: 35px;
      margin-top: 20px;
      border-top: 1px solid #5883f8;

      .sort-item {
        width: 154px;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 17px;
        color: #5883f8;
        cursor: pointer;
        position: relative;

        img {
          margin-left: 15px;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          background: #5883f8;
          transform: translateY(-50%);
          height: 20px;
          width: 1px;
        }
      }
    }

    .resource-list {
      margin-top: 7px;
      background: #ffffff;

      .resource-item {
        background: #ffffff;
        // height: 130px;
        width: 100%;
        padding: 15px 20px;
        display: block;
        box-sizing: border-box;

        &:not(:first-child) {
          border-top: 7px solid #f6f6f6;
        }

        .resource-item__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .resource-item__header-right {
            display: inline-flex;
            align-items: center;
          }
        }

        .resource-item__title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          .terms {
            margin-left: 3px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
          }
        }
        .resource-item__view {
          margin-left: 20px;
          font-size: 12px;
          color: #656565;
          display: inline-flex;
          align-items: center;

          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        .resource-item__download {
          margin-left: 20px;
          font-size: 12px;
          line-height: 17px;
          color: #656565;
          display: inline-flex;
          align-items: center;

          img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        .resource-item__category {
          margin-left: 20px;
          border: 0;
          padding: 5px 17px;
          border-radius: 2px;
          height: 30px;
          font-size: 14px;
          line-height: 20px;
        }

        .resource-item__content {
          margin-top: 18px;
          display: flex;
          align-items: center;

          .resource-item__description {
            flex: 1;
            font-size: 14px;
            color: #656565;
            line-height: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;

            .resource-item__description-title {
              margin-right: 15px;
            }
          }
        }

        .resource-item__footer {
          margin-top: 18px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          color: #656565;
          line-height: 17px;

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25%;
            &:not(:last-of-type) {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .el-pagination {
      margin-top: 6px;
      text-align: right;
      height: 100px;
      width: 100%;
      background: #ffffff;
      padding: 34px 20px;
      box-sizing: border-box;

      ::v-deep .btn-prev,
      ::v-deep .btn-next {
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #cccccc;
        padding: 8px 12px;
        display: inline-flex;
        align-items: center;
        height: 32px;
      }

      ::v-deep .el-pager {
        li {
          border-radius: 4px;
          height: 32px;
          width: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: rgba(88, 131, 248, 0.4);
            border: 1px solid #5883f8;
            color: #3673ff;
          }
        }
      }
    }
  }
}
</style>
